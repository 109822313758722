<template>
  <van-overlay :show="show">
    <div class="house-layout">
      <div class="hy-head">
        <p class="hy-title">户型列表</p>
        <img class="hy-close" @click="close" src="../../../assets/close.png" />
      </div>
      <div class="hy-body">
        <p class="hy-page">第 {{ page }} 页 共{{ pageMax }}页</p>
        <div class="hy-screen">
          <img
            src="../../../assets/arror-left.png"
            class="hy-arror"
            @click="pageDown"
          />
          <div class="hy-detail">
            <img :src="info?.thumb_path" class="hy-detail-img" />
            <p class="hy-detail-title">
              {{ info?.name }}
            </p>
            <p class="hy-detail-date">{{ info?.create_time?.slice(0, 10) }}</p>
          </div>
          <img
            src="../../../assets/arror-right.png"
            class="hy-arror"
            @click="pageUp"
          />
        </div>
        <div class="hy-buttons">
          <van-pagination
            v-model="page"
            :page-count="pageMax"
            :show-page-size="3"
          >
            <template #prev-text>
              <img
                class="page-arror"
                src="../../../assets/blue-left-arror.png"
              />
            </template>
            <template #next-text>
              <img
                class="page-arror"
                src="../../../assets/blue-right-arror.png"
              />
            </template>
          </van-pagination>
          <input class="button-input" v-model="pageJump" />
          <div class="button-go" @click="pageJumpChange">GO</div>
        </div>
        <div class="hy-bottom">
          <div class="bottom-button" @click="upload">发送户型</div>
          <p class="bottom-tips">
            单个户型最多只能分享三次,一次只能分享一个户型
          </p>
        </div>
      </div>
    </div>
  </van-overlay>
</template>

<script>
import { Toast, Overlay, Pagination } from "vant";
import API from "../../../utils/ajax";
export default {
  components: {
    [Toast.name]: Toast,
    [Overlay.name]: Overlay,
    [Pagination.name]: Pagination,
  },
  props: {
    isShow: Boolean,
    token: String,
  },
  watch: {
    page: async function() {
      let res = await API.getHouseLayout(this.page, this.token);
      this.info = res.data.results[0];
    },
  },
  async mounted() {
    let res;
    if (this.token) {
      res = await API.getHouseLayout(1, this.token);
      this.pageMax = res.data.count;
    }
    if (res?.data?.results) {
      this.info = res.data.results[0];
    }
  },
  data() {
    return {
      houseLayoutShow: false,
      //houseTypePage数据
      info: {},
      page: 0,
      pageMax: 0,
      pageJump: "",
      show: false,
      designerId: "",
    };
  },
  methods: {
    setDesignerId(id) {
      this.designerId = id;
    },
    open() {
      if (this.pageMax == 0) {
        this.$wx.miniProgram.navigateTo({
          url: `/pages/appointment/appointment?id=${this.designerId}`,
        });
      } else {
        this.show = true;
      }
    },
    close() {
      this.show = false;
    },
    pageUp() {
      if (this.page != this.pageMax) {
        this.page++;
      }
    },
    pageDown() {
      if (this.page != 1) {
        this.page--;
      }
    },
    pageJumpChange() {
      if (!isNaN(parseInt(this.pageJump))) {
        if (this.pageJump > this.pageMax) {
          this.page = this.pageMax;
        } else if (this.pageJump < 1) {
          this.page = 1;
        } else {
          this.page = this.pageJump;
        }
      }
      this.pageJump = "";
    },
    async upload() {
      let res = await API.sendAppointmentWithHouseLayout(
        {
          id: this.info.id,
          designer: this.designerId,
        },
        this.token
      ).catch((err) => {
        console.log(err);
        Toast(err.data.message);
      });
      if (res) {
        if (res.data.code == 201) {
          Toast("预约成功!");
          this.close();
        } else {
          Toast("预约失败,请重试");
        }
      }
    },
  },
};
</script>

<style src="./house-type-send.css" scoped></style>
