import constant from "./constant";

const dateParse = function(utc_datetime) {
  if (utc_datetime) {
    var T_pos = utc_datetime.indexOf("T");
    var Z_pos = utc_datetime.indexOf(".");
    var year_month_day = utc_datetime.substring(0, T_pos);
    var hour_minute_second = utc_datetime.substring(T_pos + 1, Z_pos);
    var new_datetime = year_month_day + " " + hour_minute_second; // 2019-10-17 18:09:43
    if (Z_pos === -1) {
      hour_minute_second = utc_datetime.substring(
        T_pos + 1,
        utc_datetime.length
      );
      return year_month_day + " " + hour_minute_second;
    }
    return new_datetime;
  }
};

var base64EncodeChars =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

const base64decode = function(input) {
  var output = "";
  var chr1, chr2, chr3;
  var enc1, enc2, enc3, enc4;
  var i = 0;
  input = input.replace(/[^A-Za-z0-9\\+\\/\\=]/g, "");
  while (i < input.length) {
    enc1 = base64EncodeChars.indexOf(input.charAt(i++));
    enc2 = base64EncodeChars.indexOf(input.charAt(i++));
    enc3 = base64EncodeChars.indexOf(input.charAt(i++));
    enc4 = base64EncodeChars.indexOf(input.charAt(i++));
    chr1 = (enc1 << 2) | (enc2 >> 4);
    chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
    chr3 = ((enc3 & 3) << 6) | enc4;
    output = output + String.fromCharCode(chr1);
    if (enc3 != 64) {
      output = output + String.fromCharCode(chr2);
    }
    if (enc4 != 64) {
      output = output + String.fromCharCode(chr3);
    }
  }
  return output;
};

const getHouseStyle = function(tag) {
  return constant.houseStyle.filter((ele) => ele.id == tag)[0]?.name || "未知";
};

const getHouseType = function(id) {
  return constant.houseType.filter((ele) => ele.id == id)[0]?.name || "未知";
};

const getDesignerIdentity = function(star_level) {
  return (
    constant.designerIdentity.filter((ele) => ele.level == star_level)[0]
      ?.name || "未知"
  );
};

const getContractStatus = function(status) {
  return (
    constant.contractPayStatus.filter((ele) => ele.status == status)[0]?.name ||
    "未知"
  );
};

export default {
  dateParse,
  base64decode,
  getHouseStyle,
  getHouseType,
  getDesignerIdentity,
  getContractStatus,
};
