let version = process.env.VUE_APP_VERSION || "release";
// let version = "release";
//develop-开发   trial-测试   release-线上

let baseUrl = "";
if (version === "develop") {
  baseUrl = "https://hive.g-sir.com.cn/internal/"; // 开发版
} else if (version === "trial") {
  baseUrl = "https://hive.g-sir.com.cn/internal/"; // 测试版
} else {
  baseUrl = "https://hive.g-sir.com.cn/api/"; // 线上版
}

let QUEEN_BASE = "";
if (version === "develop") {
  QUEEN_BASE = "queen/api"; // 开发版
} else if (version === "trial") {
  QUEEN_BASE = "queen/api"; // 开发版
} else {
  QUEEN_BASE = "queen"; // 线上版
}

let SAKURA_BASE = "";
if (version === "develop") {
  SAKURA_BASE = "sakura/api"; // 开发版
} else if (version === "trial") {
  SAKURA_BASE = "sakura/api"; // 开发版
} else {
  SAKURA_BASE = "sakura"; // 线上版
}

import axios from "axios";

//取得地址信息
function REQUEST(fromData, method, url, data, token) {
  return new Promise(function(resolve, reject) {
    let header = "";
    let mydata = "";
    if (fromData) {
      header = {
        "content-type": "application/x-www-form-urlencoded",
      };
      mydata = data;
    } else {
      header = {
        "content-type": "application/json",
      };
      mydata = method == "post" ? JSON.stringify(data) : data;
    }
    if (token) {
      Object.assign(header, {
        Authorization: `G-sir ${token}`,
      });
    }
    axios({
      method: method,
      url: `${baseUrl + url}`,
      headers: header,
      data: mydata,
    })
      .then((res) => {
        if (res.data.code == 200 || res.data.code == 201) {
          resolve(res.data);
        } else {
          reject(res.data);
        }
      })
      .catch((err) => {
        reject(err);
        console.log(err);
      });
  });
}

//测试环境
//获取地区信息
const API = {
  getArea: (data) => {
    return REQUEST(
      false,
      "get",
      `${QUEEN_BASE}/account/area_list/?pcode=${data}`
    );
  },
  //取得真实案例列表数据
  getCaseList: (page, pageSize, data, isRealCase) => {
    return REQUEST(
      false,
      "get",
      `${SAKURA_BASE}/v2/case?type=${isRealCase}&page=${page}&page_size=${pageSize}&is_show=1&ordering=-order,-create_time&house_type=${data.house_type ||
        ""}&tags=${data.tags || ""}&price_min=${data.minPrice ||
        ""}&price_max=${data.maxPrice || ""}&zone=${data.areaCode ||
        ""}&need_appraise=${data.need_appraise ||
        ""}&designer=${data.designer_id || ""}`
    );
  },
  //取得数据词典数据
  getDataDict: () => {
    return REQUEST(false, "get", `${QUEEN_BASE}/common/data_dict/`);
  },
  //取得案例详情数据
  getCaseDetail: (id, token) => {
    return REQUEST(false, "get", `${SAKURA_BASE}/v2/case/${id}/`, {}, token);
  },
  //取得报价清单数据
  getOfferDetails: (id) => {
    return REQUEST(false, "get", `glissade/design/replace_budget/${id}/`);
  },
  //取得日志详情
  getDiaryDetail: (id) => {
    return REQUEST(
      false,
      "get",
      `${SAKURA_BASE}/v2/diary?design=${id}&type=1&page=1&ordering=-create_time,-modify_time&operation=1&zone=310000000000&page_size=4`
    );
  },
  //发送预约请求
  sendAppointment: (data) => {
    return REQUEST(true, "post", `${QUEEN_BASE}/operation/preorder/`, data);
  },
  //取得户型图
  getHouseLayout: (page, token) => {
    return REQUEST(
      false,
      "get",
      `${QUEEN_BASE}/house_layout/house_layout_platform/?page=${page}&page_size=1&ordering=-modify_time,-create_time&verify_state=2&preorder=1`,
      {},
      token
    );
  },
  //带着预约图发送月月请求
  sendAppointmentWithHouseLayout: (data, token) => {
    return REQUEST(
      false,
      "post",
      `${QUEEN_BASE}/operation/preorder_designer/`,
      data,
      token
    );
  },
  //收藏
  addOrRemoveCaseCollection: (isCollection, id, type, token) => {
    if (isCollection) {
      return REQUEST(
        true,
        "post",
        `${SAKURA_BASE}/jwt/remove_favorite/${id}/`,
        {},
        token
      );
    } else {
      return REQUEST(
        false,
        "post",
        `${SAKURA_BASE}/jwt/favorite`,
        { target_id: id, type: type },
        token
      );
    }
  },
  //取得设计师列表
  getDesignerList(page, pageSize, searchInfo) {
    return REQUEST(
      false,
      "get",
      `${QUEEN_BASE}/designer/designers/?page=${page}&page_size=${pageSize}&ordering=-star_level%2C-order%2C-design_case_count%2Ccreate_time&need_case=1&search=${searchInfo.searchWord}&zone=${searchInfo.areaCode}&skill=${searchInfo.tags}&price_min=${searchInfo.minPrice}&price_max=${searchInfo.maxPrice}`,
      {}
    );
  },
  //取得设计师详情
  getDesignerDetail(id) {
    return REQUEST(false, "get", `${QUEEN_BASE}/designer/designers/${id}/`, {});
  },
  //取得设计师成交记录
  getDesignerRecordList(designerId, page, pageSize) {
    return REQUEST(
      false,
      "get",
      `${QUEEN_BASE}/contract/contract_list/?designer=${designerId}&page=${page}&page_size=${pageSize}&ordering=-modify_time`,
      {}
    );
  },
  //取得设计师评价
  getDesignerAppraiseList(designerId, page, pageSize) {
    return REQUEST(
      false,
      "get",
      `${SAKURA_BASE}/v2/appraise?designer=${designerId}&page=${page}&page_size=${pageSize}&ordering=-modify_time`,
      {}
    );
  },
  //取得单一评价详情
  getSingleAppraiseDetail(id) {
    return REQUEST(false, "get", `${SAKURA_BASE}/v2/appraise/${id}/`, {});
  },
};

export default API;
